* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
// h1,
// h2,
// h3 {
// 	color: rgb(56, 56, 56);
// }
body {
	font-family: 'Lato', sans-serif;
}
* {
	scrollbar-width: thin;
	scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

*::-webkit-scrollbar {
	width: 5px;
}
*::-webkit-scrollbar-track {
	background: transparent;
}
*::-webkit-scrollbar-thumb {
	background-color: rgba(155, 155, 155, 0.5);
	border-radius: 20px;
	border: transparent;
}
