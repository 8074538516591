.search {
	position: absolute;
	z-index: 3;
	top: 1rem;
	left: 13.2rem;
	width: 22%;
	height: 2rem;

	.icon {
		position: relative;
		font-size: 1.3rem;
		top: 0.5rem;
		left: 5.1rem;
		/* z-index: 6; */
		z-index: 3;
		@media (max-width: 700px) {
			left: 3.7rem;
		}
	}

	@media (max-width: 800px) {
		top: 1;
		// left: 120px;
		// width: 240px;
	}

	@media (max-width: 500px) {
		left: 2rem;
		width: 2rem;
		z-index: 0;
	}

	input {
		position: relative;
		top: 0;
		left: 3.5rem;
		width: 15rem;
		height: 2.2rem;
		border: 2px solid #082b84;
		border-radius: 7px;
		padding-left: 1.5rem;
		font-size: 13px;
		color: #222222;
		letter-spacing: 0.1rem;
		background-color: white;
		// width: 100%;
		-webkit-appearance: none;
		outline: none;
		background: rgb(226, 224, 224);
		cursor: pointer;
		/* background-image: url(https://res.cloudinary.com/dfulxq7so/image/upload/v1573052834/698627-icon-111-search-512_lxvbpg.png); */
		/* background-position: 10px 10px; */
		/* background-repeat: no-repeat;*/
		@media (max-width: 700px) {
			top: -1.6rem;
			right: 0;
			width: 12rem;
			font-size: 10px;
		}
	}

	.filtered {
		width: 72%;
		min-width: 15ch;
		max-width: 30ch;
		border-radius: 0.25em;
		padding: 0.25em 1.5em;
		font-size: 1.25rem;
		cursor: pointer;
		line-height: 1.1;
		background-color: rgba(236, 236, 236, 0.884);
		border: 1px solid #082b84;
		border-bottom-left-radius: 25px;
		border-bottom-right-radius: 25px;
		margin-left: 5rem;
		h2 {
			font-size: 14px;
		}
	}

	button {
		float: right;
		padding: 6px 10px;
		margin-top: 8px;
		margin-right: 16px;
		background: #ddd;
		font-size: 17px;
		border: none;
		cursor: pointer;
	}
}
