.mapboxgl-popup-content {
	position: relative;
	background: #fff;
	border-radius: 7px;
	// box-shadow: 20px 11px 4px rgb(0 0 0 / 10%);
	/* padding: 10px 10px 15px; */
	padding: 0.2rem 2.5rem;
	pointer-events: auto;
}

.map {
	position: relative;
}

.popupCard {
	width: 270px;
	border-top: 10px solid blue;
	border-radius: 15px;
}
.popupInfo .pump_info .spread {
	display: flex;
	padding-top: 8px;
	margin-right: 0.5em;
}

.popupInfo .pump_info .province_name {
	font-size: 14px;
	margin: 10px 11px 0px 0px;
	font-family: 'Poppins', sans-serif;
}

.popupInfo .pump_id .pump_num {
	padding-top: 14px;
	font-size: 14px;
	font-family: 'Poppins', sans-serif;
	/* font-family: sans-serif; */
}

.ant-btn-link {
	margin: 0 0em;
	color: white;
	background-color: blue;
	border-color: transparent;
	box-shadow: none;
	margin-top: 0.9em;
	// margin-right: 4.5em;
}
