.modal {
    font-size: 15px;
    padding: 1px;
    border: 2px solid skyblue;
    text-align: center;
  }
  .modal > .header {
    width: 100%;
    border-bottom: 1px solid skyblue;
    font-size: 22px;
    text-align: center;
    padding: 8px;
  }
  .modal > .content {
    width: 100%;
    padding: 5px 5px;
  }
  .modal > .actions {
    margin: auto;
  }
  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    text-align: center;
  }
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }

  .overviewpopup {
    color: navy
  }