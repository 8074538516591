:root {
	--select-border: #777;
	--select-focus: rgb(64, 64, 160);
	--select-arrow: var(--select-border);
}

.filter-expand {
	cursor: pointer;
	border-radius: 4px;
	background-color: #082b84;
	color: white;
	padding: 10px;
	font-size: 14px;
	font-weight: bold;
	width: 200px;
	height: 34px;
	z-index: 4;
	top: 1rem;
	right: 50px;
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: all 1.5s ease-in-out;

	span {
		color: white;
		background-color: white;
		width: 13px;
		height: 3px;
	}

	@media (max-width: 800px) {
		width: 100px;
	}

	@media (max-width: 500px) {
		width: 80px;
	}
}

.filter {
	border-radius: 5px;
	border: 2px solid blue;
	padding: 1rem 1rem;
	/* padding-top: 20px; */
	/* padding-left: 12px; */
	/* padding-right: 12px; */
	/* padding-bottom: 5px; */
	background-color: #e4e4e4;
	width: 13rem;
	position: absolute;
	z-index: 4;
	top: 3rem;
	right: 2.9rem;
	/* transition: all 1s ease-out; */
	border: 2px solid #082b84;

	h4 {
		letter-spacing: 0.1rem;
		padding: 0.4rem 3.1rem;
		font-size: 0.9rem;
		/* text-align: center; */
		font-weight: 600;
	}

	.select-village {
		// box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
		// display: block;
		// appearance: none;
		// font-size: 14px;
		// color: rgba(0, 0, 0, 0.87);
		// padding: 5px;
		// width: 100%;
		// max-width: 100%;
		// box-sizing: border-box;
		// border: 2px solid #aaa;
		// border-radius: 10px;
		// background-color: rgb(212, 212, 212);
		// outline: none;
		width: 100%;
		min-width: 15ch;
		max-width: 30ch;
		border: 1px solid #777;
		border-radius: 0.25em;
		padding: 0.25em 0.5em;
		font-size: 1.25rem;
		cursor: pointer;
		line-height: 1.1;
		background-color: rgba(236, 235, 235, 0.815);
		background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
		display: grid;
		grid-template-areas: 'select';
		align-items: center;

		.select::after {
			content: '';
			width: 0.8em;
			height: 0.5em;
			background-color: rgb(64, 64, 160);
			clip-path: polygon(100% 0%, 0 0%, 50% 100%);
			justify-self: end;
		}
	}

	select,
	.select:after {
		grid-area: select;
	}
	.pump-type {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	p {
		margin-right: 0.9rem;
		font-weight: 800;
		font-size: 1rem;
		padding-top: 0.2rem;
	}
}
