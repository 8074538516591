.header {
	// background: rgb(255, 255, 255);
	// background: linear-gradient(
	// 	180deg,
	// 	rgba(255, 255, 255, 1) 0%,
	// 	rgba(8, 43, 132, 0.7035189075630253) 77%,
	// 	rgba(0, 0, 0, 0.6110819327731092) 98%
	// );

	background: rgb(8, 43, 132);
	background: radial-gradient(
		circle,
		rgba(8, 43, 132, 1) 33%,
		rgba(213, 213, 213, 0.9444152661064426) 71%
	);

	// background: rgb(8, 43, 132);
	// background: linear-gradient(
	// 	180deg,
	// 	rgba(8, 43, 132, 1) 44%,
	// 	rgba(0, 0, 0, 1) 98%,
	// 	rgba(255, 255, 255, 1) 100%
	// );
	height: 4.2rem;
	border-bottom: 3px solid #082b84;
	.header-logo {
		position: relative;
		top: 0.4rem;
		left: 44rem;
		height: 3.5rem;
		width: 17rem;
		&:hover {
			// background: rgba(65, 65, 65, 0.082);
			transition: all 0.5s ease-out;
			border: none;
			border-radius: 5%;
			filter: brightness(150%) contrast(50%);
		}
	}
}

.hamburger-menu {
	position: relative;
	z-index: 1;
	top: -2.1rem;
	left: 3rem;
	width: 5%;
	height: 40%;

	&:hover {
		cursor: pointer;
		color: #7494e6;
	}
	svg:not(:root) {
		overflow: hidden;
		top: -0.8rem;
		position: relative;
		font-size: 3rem;
		color: #082b84;
		&:hover {
			cursor: pointer;
			transition: all 0.5s ease-in-out;
			filter: brightness(250%) contrast(50%);
		}
		@media (max-width: 600px) {
			right: 2rem;
			z-index: 5;
		}
	}

	.hamburger-icon {
		color: #082b84;
		// width: 25px;
		// height: 2rem;
		// /* display: block; */
		font-size: 3.2rem;
		&:hover {
			cursor: pointer;
			transition: all 0.3s ease-out;
			border: none;
			border-radius: 5%;
			filter: brightness(150%) contrast(50%);
		}
	}
	@media (max-width: 600px) {
		z-index: 5;
	}
}

.overlay {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	// background-color: #082B84;
	background-color: #082b84;
	overflow-x: hidden;
	transition: 0.3s;

	@media (max-width: 600px) {
		height: 100%;
		z-index: 7;
	}

	.closebtn {
		position: relative;
		top: -20px;
		right: -170px;
		font-size: 30px;
		color: #818181;
	}

	.intro {
		background-color: #082b84;
		width: 214px;
		height: auto;
		color: #ffffff;
		position: absolute;
		// padding: 30px;

		.name {
			font-size: 16px;
			margin-left: 1.8rem;
			letter-spacing: 0.1rem;
		}

		.email-address {
			font-size: 12px;
			margin-left: 1.8rem;
		}

		.span {
			background-color: rgba(255, 255, 255, 0.8);
			display: block;
			width: 214px;
			height: 1px;
		}

		.logo {
			// width: 80%;
			margin: -20px 0 10px 20px;
			width: 170.81px;
			height: 35.61px;
		}
	}

	.overlay-content-map {
		position: relative;
		top: 18%;
		width: 100%;
		text-align: left;
		// margin-top: 30px;
		color: white;
		//   padding-left: 30px;
		//   margin: 0px;
		//   margin-left: 10px;

		//   a:hover {
		//       // background-color: rgba(48, 155, 242, 0.637);
		//       // width: 194px;
		//       // height: 30px;
		//     //   color:#818181;

		//   }

		.eachNav {
			display: flex;
			//   align-items: center;
			//   align-self: flex-end;
			justify-items: space-evenly;
			width: 100%;
			padding-left: 30px;
			align-items: center;
			//   margin-left: 10px;
		}
		.eachNav:hover {
			background-color: #1c49bb;
			color: white;
		}
	}

	a {
		padding: 16px 0 16px 8px;
		text-decoration: none;
		font-size: 20px;
		color: #ffffff;
		display: block;
		transition: 0.3s;
		margin-top: 0px;
		// text-align: center;

		//   :hover, :focus {
		//       color: white;

		//     }
	}
}
