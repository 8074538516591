.mobile-hamburger-menu {
  margin: 25px;
  position: absolute;
  z-index: 1;

  .mobile-span {
    background-color: white;
    width: 30px;
    height: 5px;
    display: block;
    margin-bottom: 5px;
  }
}

.mobile-overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #082b84;
  overflow-x: hidden;
  transition: 0.5s;

  .mobile-closebtn {
    right: 170px;
    font-size: 35px;
    color: #818181;
    .close-x {
      padding-left: 210px;
    }
  }

  .mobile-intro {
    background-color: #082b84;
    width: 214px;
    height: 725px;
    color: #ffffff;
    position: absolute;

    .mobile-name {
      font-size: 16px;
      margin-left: 20px;
    }

    .mobile-email-address {
      font-size: 12px;
      margin-left: 20px;
    }

    span {
      background-color: rgba(255, 255, 255, 0.8);
      display: block;
      width: 100vh;
      height: 1px;
    }

    .mobile-logo {
      margin: -20px 0 10px 20px;
      width: 170.81px;
      height: 35.61px;
    }
  }

  .mobile-overlay-content {
    position: relative;
    top: 18%;
    width: 100%;
    text-align: left;
    color: white;

    .mobile-eachNav {
      display: flex;
      justify-items: space-evenly;
      width: 100%;
      padding-left: 30px;
      align-items: center;
    }
    .mobile-eachNav:hover {
      background-color: #1c49bb;
      color: white;
    }
  }

  a {
    padding: 16px 0 16px 8px;
    text-decoration: none;
    font-size: 20px;
    color: #ffffff;
    display: block;
    transition: 0.3s;
    margin-top: 0px;
  }
}
